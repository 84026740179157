






import { Component, Vue } from "vue-property-decorator";
@Component
export default class NewVueComponent extends Vue {
  variable = null;
}
